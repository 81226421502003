(function ($, undefined) {
    $.fn.serializeObject = function () {
        var obj = {};

        $.each(this.serializeArray(), function (i, o) {
            var n = o.name,
                v = o.value;

            obj[n] = obj[n] === undefined ? v
                : $.isArray(obj[n]) ? obj[n].concat(v)
                : [obj[n], v];
        });

        return obj;
    };
})(jQuery);


function decl(number, titles) {
    /*
        usage for 1 element, 2 element or 10 elements:
        decl(count, ['яблоко', 'яблака', 'яблок']);
     */
    var cases = [2, 0, 1, 1, 1, 2];
    var number = Math.abs(number);
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}